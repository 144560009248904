@charset "UTF-8";
.container {
  max-width: 0rem; }

@media screen and (min-width: 48em) {
  .container {
    max-width: 48rem; } }

@media screen and (min-width: 64em) {
  .container {
    max-width: 64rem; } }

@media screen and (min-width: 90em) {
  .container {
    max-width: 90rem; } }

@media screen and (min-width: 112.5em) {
  .container {
    max-width: 112.5rem; } }

/*

Usage:

// With generated breakpoints
@include spacing($size:small, $props:('margin-top','margin-bottom'));

// As a property value
margin-top: spacing($size:small, $breakpoint:small);

*/
/*

Usage:

// With generated breakpoints
@include font-size($size:small, $props:('margin-top','margin-bottom'));

// As a property value
margin-top: font-size($size:small, $breakpoint:small);

*/
/* ----------------------------------- //
//      CSS Hacks Mixins for Sass
// ----------------------------------- //
	How to use:
	@include [Mixin_Name]( 'selector', (property: value) )
	Example:
	@include only_ie9( '.my_element', (color: red) )
	@include only_ff28_above( '.my_element', (
		background-color: green,
		display: flex,
		margin: 2em,
	))
	Tips:
	  - Use firefox mixins before IE mixins. Sometimes Firefox wants to precess the IE css but it can't and skips it's own

/*--- Only IE ≤ 11 ---*/
@keyframes dot {
  0% {
    transform: translateY(-50%); }
  50% {
    transform: translateY(100%); }
  100% {
    transform: translateY(-50%); } }

@keyframes shake-horizontal {
  0%,
  100% {
    transform: translateX(0); }
  10%,
  30%,
  50%,
  70% {
    transform: translateX(-6px); }
  20%,
  40%,
  60% {
    transform: translateX(6px); }
  80% {
    transform: translateX(3px); } }

@keyframes scroll-arrow {
  0% {
    transform: translateY(0); }
  2.77778% {
    transform: translateY(0); }
  5.55556% {
    transform: translateY(0); }
  11.11111% {
    transform: translateY(-10px); }
  13.88889% {
    transform: translateY(0); }
  16.66667% {
    transform: translateY(-10px); }
  22.22222% {
    transform: translateY(0); }
  50% {
    transform: translateY(0); }
  100% {
    transform: translateY(0); } }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
* {
  box-sizing: border-box; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body {
  width: 100%;
  overflow-x: hidden; }

figure {
  margin: 0; }

img {
  width: 100%; }

button {
  appearance: none;
  border-radius: 0;
  background: none;
  color: currentColor;
  font-size: inherit;
  border: none;
  padding: 0;
  vertical-align: middle;
  font-family: "acumin-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  line-height: 1.35; }
  button:hover {
    cursor: pointer; }

figure {
  position: relative; }

input,
textarea {
  appearance: none;
  border: none;
  color: black;
  font-size: 1.25rem;
  font-family: "acumin-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif; }
  @media screen and (min-width: 48em) {
    input,
    textarea {
      font-size: 2.125rem; } }
  @media screen and (min-width: 64em) {
    input,
    textarea {
      font-size: 2.125rem; } }
  input::placeholder,
  textarea::placeholder {
    color: rgba(0, 0, 0, 0.5); }
  input:focus,
  textarea:focus {
    outline: none; }

textarea {
  height: auto; }

select {
  font-size: 1.125rem;
  font-weight: 400;
  appearance: none;
  border: none;
  background: none;
  font-family: "acumin-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif; }
  @media screen and (min-width: 48em) {
    select {
      font-size: 1.25rem; } }
  @media screen and (min-width: 64em) {
    select {
      font-size: 1.25rem; } }
  select::-ms-expand {
    display: none; }
  select option {
    color: black; }
  select:hover {
    cursor: pointer; }

input[type='number'] {
  -moz-appearance: textfield; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none; }

hr {
  height: 4px;
  width: 100%;
  background: black;
  border: none; }

html {
  font-size: 100%; }

body {
  font-family: "acumin-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.35; }
  @media screen and (min-width: 48em) {
    body {
      font-size: 2.125rem; } }
  @media screen and (min-width: 64em) {
    body {
      font-size: 2.125rem; } }

::-webkit-scrollbar {
  display: none; }

::selection {
  background: black;
  color: white; }

h1,
.h1 {
  font-size: 2.125rem;
  font-family: "acumin-pro-wide", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  line-height: 1.13;
  font-weight: 500; }
  @media screen and (min-width: 48em) {
    h1,
    .h1 {
      font-size: 3.75rem; } }
  @media screen and (min-width: 64em) {
    h1,
    .h1 {
      font-size: 3.75rem; } }
  @media screen and (min-width: 48em) {
    h1,
    .h1 {
      line-height: 1.083333333; } }

h2,
.h2 {
  font-size: 2.125rem;
  line-height: 1.13;
  font-family: "acumin-pro-wide", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  margin-bottom: 2.5rem;
  font-weight: 500; }
  @media screen and (min-width: 48em) {
    h2,
    .h2 {
      font-size: 3.75rem; } }
  @media screen and (min-width: 64em) {
    h2,
    .h2 {
      font-size: 3.75rem; } }
  @media screen and (min-width: 48em) {
    h2,
    .h2 {
      margin-bottom: 3.75rem; } }
  @media screen and (min-width: 64em) {
    h2,
    .h2 {
      margin-bottom: 3.75rem; } }
  @media screen and (min-width: 48em) {
    h2,
    .h2 {
      line-height: 1.083333333; } }

h3,
.h3 {
  font-family: "acumin-pro-wide", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.25rem;
  font-weight: 500; }
  @media screen and (min-width: 48em) {
    h3,
    .h3 {
      font-size: 2.125rem; } }
  @media screen and (min-width: 64em) {
    h3,
    .h3 {
      font-size: 2.125rem; } }

small {
  font-size: 1.125rem;
  line-height: 1.25; }
  @media screen and (min-width: 48em) {
    small {
      font-size: 1.25rem; } }
  @media screen and (min-width: 64em) {
    small {
      font-size: 1.25rem; } }

strong {
  font-weight: 500; }

*:focus {
  outline-color: black; }

a {
  cursor: pointer;
  position: relative;
  text-decoration: underline;
  color: currentColor;
  transition: opacity 0.3s ease; }
  a:hover {
    text-decoration: none;
    opacity: 0.6; }

a[href^='tel'] {
  font-stretch: normal; }

.development:after {
  position: fixed;
  color: orange;
  left: 2.5rem;
  bottom: 2.5rem;
  font-size: 1.125rem;
  font-weight: 400; }
  @media screen and (min-width: 48em) {
    .development:after {
      left: 3.75rem;
      bottom: 3.75rem; } }
  @media screen and (min-width: 64em) {
    .development:after {
      left: 3.75rem;
      bottom: 3.75rem; } }
  @media screen and (min-width: 48em) {
    .development:after {
      font-size: 1.25rem; } }
  @media screen and (min-width: 64em) {
    .development:after {
      font-size: 1.25rem; } }

.development:after {
  content: "sm"; }

@media screen and (min-width: 48em) {
  .development:after {
    content: "md"; } }

@media screen and (min-width: 64em) {
  .development:after {
    content: "lg"; } }

@media screen and (min-width: 90em) {
  .development:after {
    content: "xl"; } }

@media screen and (min-width: 112.5em) {
  .development:after {
    content: "xxl"; } }

.container {
  position: relative;
  width: 100%;
  max-width: 100%;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-left: 1.875rem;
  padding-right: 1.875rem; }
  @media screen and (min-width: 48em) {
    .container {
      padding-left: 3.75rem;
      padding-right: 3.75rem; } }
  @media screen and (min-width: 64em) {
    .container {
      padding-left: 3.75rem;
      padding-right: 3.75rem; } }
  @media screen and (min-width: 48em) {
    .container {
      max-width: 100rem;
      margin-left: auto;
      margin-right: auto; } }
  @media screen and (min-width: 90em) {
    .container {
      max-width: 100rem;
      margin-left: auto;
      margin-right: auto; } }

.editor h2, .Content__text h2, .Content__caption h2, .Project__caption h2 {
  font-family: "acumin-pro-wide", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif; }

.editor a, .Content__text a, .Content__caption a, .Project__caption a {
  text-decoration: underline; }
  .editor a:hover, .Content__text a:hover, .Content__caption a:hover, .Project__caption a:hover {
    text-decoration: none; }

.button {
  cursor: pointer;
  display: inline-block;
  position: relative;
  display: inline-block;
  font-family: "acumin-pro-wide", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 500;
  vertical-align: middle;
  text-align: center;
  width: 100%;
  text-decoration: none; }
  .button:hover {
    opacity: 1; }
  .button__inner {
    display: block;
    transition: 0.12s ease;
    border: 4px solid black;
    padding: 0.9375rem 1.5625rem 1.25rem 1.5625rem; }
    .button__inner:hover {
      border: 4px solid black;
      background: black;
      color: white;
      opacity: 1; }
      .button__inner:hover .button__icon path {
        stroke: white; }
  .button__icon {
    transform: translateY(0.375rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem; }

.page {
  position: relative;
  max-width: 100%;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  margin-bottom: 5rem;
  width: 100%; }
  @media screen and (min-width: 48em) {
    .page {
      padding-left: 3.75rem;
      padding-right: 3.75rem; } }
  @media screen and (min-width: 64em) {
    .page {
      padding-left: 3.75rem;
      padding-right: 3.75rem; } }
  @media screen and (min-width: 48em) {
    .page {
      max-width: 100rem;
      margin-left: auto;
      margin-right: auto; } }
  @media screen and (min-width: 90em) {
    .page {
      max-width: 100rem;
      margin-left: auto;
      margin-right: auto; } }
  @media screen and (min-width: 48em) {
    .page {
      margin-bottom: 10rem; } }
  @media screen and (min-width: 64em) {
    .page {
      margin-bottom: 10rem; } }

.Article {
  padding-top: 3.75rem;
  margin-bottom: 5rem; }
  @media screen and (min-width: 48em) {
    .Article {
      padding-top: 5rem; } }
  @media screen and (min-width: 64em) {
    .Article {
      padding-top: 5rem; } }
  @media screen and (min-width: 48em) {
    .Article {
      margin-bottom: 10rem; } }
  @media screen and (min-width: 64em) {
    .Article {
      margin-bottom: 10rem; } }

.Header {
  margin-bottom: 2.5rem;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  display: flex;
  justify-content: space-between;
  align-items: baseline; }
  @media screen and (min-width: 48em) {
    .Header {
      margin-bottom: 3.75rem; } }
  @media screen and (min-width: 64em) {
    .Header {
      margin-bottom: 3.75rem; } }
  @media screen and (min-width: 48em) {
    .Header {
      padding-left: 2.5rem;
      padding-right: 2.5rem; } }
  @media screen and (min-width: 48em) and (min-width: 48em) {
    .Header {
      padding-left: 3.75rem;
      padding-right: 3.75rem; } }
  @media screen and (min-width: 48em) and (min-width: 64em) {
    .Header {
      padding-left: 3.75rem;
      padding-right: 3.75rem; } }

.Menu {
  display: flex; }
  .Menu__wrapper {
    display: none;
    white-space: nowrap; }
    @media screen and (min-width: 64em) {
      .Menu__wrapper {
        display: block; } }
    @media screen and (min-width: 112.5em) {
      .Menu__wrapper {
        margin-right: 0; } }
  .Menu__secondary {
    margin-left: 5rem; }
    @media screen and (min-width: 48em) {
      .Menu__secondary {
        margin-left: 10rem; } }
    @media screen and (min-width: 64em) {
      .Menu__secondary {
        margin-left: 10rem; } }
  .Menu__mobile {
    display: block; }
    @media screen and (min-width: 64em) {
      .Menu__mobile {
        display: none; } }
  .Menu__item {
    display: inline-block;
    font-family: "acumin-pro-wide", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-weight: 500;
    text-decoration: none;
    margin-right: 0.625rem;
    line-height: 1; }
    @media screen and (min-width: 48em) {
      .Menu__item {
        margin-right: 1.25rem; } }
    @media screen and (min-width: 64em) {
      .Menu__item {
        margin-right: 1.25rem; } }
    .Menu__item:hover {
      opacity: 1;
      text-decoration: underline;
      border-bottom: 4px solid black; }
    @media screen and (min-width: 112.5em) {
      .Menu__item:last-child {
        margin-right: 0; } }
    .Menu__item--is-active {
      border-bottom: 4px solid black; }
  .Menu__trigger {
    font-family: "acumin-pro-wide", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif; }
    @media screen and (min-width: 48em) {
      .Menu__trigger {
        min-width: 147px; } }
  .Menu__trigger-text {
    display: inline-block;
    transform: translateY(-2px);
    margin-right: 0.9375rem; }
  @media screen and (min-width: 48em) {
    .Menu__trigger-icon {
      transform: translateY(2px); } }

.Icon {
  stroke-width: 0.25rem;
  width: 1.5rem;
  max-height: 1.5rem;
  height: 1.5rem; }
  @media screen and (min-width: 48em) {
    .Icon {
      stroke-width: 0.25rem;
      width: 2.125rem;
      max-height: 2.125rem;
      height: 2.125rem; } }
  .Icon--small {
    stroke-width: 0.25rem;
    width: 1.25rem;
    height: 1.25rem; }

.SideNav {
  font-size: 2.125rem;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  pointer-events: none; }
  @media screen and (min-width: 48em) {
    .SideNav {
      font-size: 1.25rem; } }
  @media screen and (min-width: 48em) and (min-width: 48em) {
    .SideNav {
      font-size: 2.125rem; } }
  @media screen and (min-width: 48em) and (min-width: 64em) {
    .SideNav {
      font-size: 2.125rem; } }
  .SideNav--is-open {
    pointer-events: all; }
  .SideNav__wrapper {
    padding-top: 2.5rem;
    padding-left: 3.75rem;
    padding-right: 5rem;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    max-width: 100%;
    height: 100vh;
    background: black;
    color: white; }
    @media screen and (min-width: 48em) {
      .SideNav__wrapper {
        padding-top: 3.75rem; } }
    @media screen and (min-width: 64em) {
      .SideNav__wrapper {
        padding-top: 3.75rem; } }
    @media screen and (min-width: 48em) {
      .SideNav__wrapper {
        padding-left: 5rem; } }
    @media screen and (min-width: 64em) {
      .SideNav__wrapper {
        padding-left: 5rem; } }
    @media screen and (min-width: 48em) {
      .SideNav__wrapper {
        padding-right: 10rem; } }
    @media screen and (min-width: 64em) {
      .SideNav__wrapper {
        padding-right: 10rem; } }
    .SideNav__wrapper:after {
      content: '';
      position: absolute;
      top: 0;
      left: 100%;
      width: 3.75rem;
      height: 100%;
      background: black; }
  .SideNav__nav {
    margin-bottom: 3.75rem; }
    @media screen and (min-width: 48em) {
      .SideNav__nav {
        margin-bottom: 5rem; } }
    @media screen and (min-width: 64em) {
      .SideNav__nav {
        margin-bottom: 5rem; } }
    .SideNav__nav:before {
      content: '';
      display: block;
      height: 2.125rem;
      margin-bottom: 3.75rem; }
      @media screen and (min-width: 48em) {
        .SideNav__nav:before {
          margin-bottom: 5rem; } }
      @media screen and (min-width: 64em) {
        .SideNav__nav:before {
          margin-bottom: 5rem; } }
  .SideNav__item {
    display: block;
    font-family: "acumin-pro-wide", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    margin-bottom: 0.75rem;
    text-decoration: none; }
    .SideNav__item--is-active {
      text-decoration: underline; }
  .SideNav__backdrop {
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100vh; }
  .SideNav__close {
    position: absolute;
    width: 2.1875rem;
    right: 1.875rem;
    line-height: 0; }
    @media screen and (min-width: 48em) {
      .SideNav__close {
        right: 2.5rem; } }
    @media screen and (min-width: 64em) {
      .SideNav__close {
        right: 2.5rem; } }

.Social {
  white-space: nowrap; }
  @media screen and (min-width: 48em) {
    .Social {
      display: flex; } }
  .Social__item {
    display: inline-block;
    text-transform: capitalize;
    margin-right: 0.625rem;
    font-size: 1.125rem;
    font-weight: 400; }
    @media screen and (min-width: 48em) {
      .Social__item {
        margin-right: 1.25rem; } }
    @media screen and (min-width: 64em) {
      .Social__item {
        margin-right: 1.25rem; } }
    @media screen and (min-width: 48em) {
      .Social__item {
        font-size: 1.25rem; } }
    @media screen and (min-width: 64em) {
      .Social__item {
        font-size: 1.25rem; } }
    .Social__item:last-of-type {
      margin-right: 0; }

.Content {
  display: flex;
  flex-direction: column; }
  .Content--show .Content__text, .Content--show .Content__line {
    text-decoration: none;
    color: black;
    border-bottom: none; }
  .Content--show .Content__image {
    opacity: 1; }
  .Content__link {
    display: inline-block;
    width: 100%;
    overflow: hidden; }
    .Content__link:hover {
      opacity: 1; }
  .Content__image {
    opacity: 0; }
  .Content__line {
    border-bottom: 6px solid black; }
  .Content__text {
    position: relative;
    max-width: 60.125rem;
    text-decoration: underline;
    text-decoration-color: black;
    color: transparent; }
    .Content__text--to-right {
      align-self: flex-end; }
  .Content__caption {
    font-size: 1.125rem;
    font-weight: 400;
    margin-top: 0.625rem;
    text-align: center; }
    @media screen and (min-width: 48em) {
      .Content__caption {
        font-size: 1.25rem; } }
    @media screen and (min-width: 64em) {
      .Content__caption {
        font-size: 1.25rem; } }
    @media screen and (min-width: 48em) {
      .Content__caption {
        margin-top: 1.25rem; } }
    @media screen and (min-width: 64em) {
      .Content__caption {
        margin-top: 1.25rem; } }

.embed {
  position: relative;
  max-height: 60.125rem; }
  .embed__pusher {
    width: 100%;
    height: 0;
    max-height: 50rem;
    overflow: hidden;
    pointer-events: none; }
  .embed iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.Hero {
  margin-top: 5rem;
  margin-bottom: 5rem;
  display: flex;
  flex-direction: column; }
  @media screen and (min-width: 48em) {
    .Hero {
      margin-top: 10rem; } }
  @media screen and (min-width: 64em) {
    .Hero {
      margin-top: 10rem; } }
  @media screen and (min-width: 48em) {
    .Hero {
      margin-bottom: 10rem; } }
  @media screen and (min-width: 64em) {
    .Hero {
      margin-bottom: 10rem; } }
  .Hero--show .Hero__title,
  .Hero--show .Hero__desc {
    text-decoration: none;
    color: black; }
  .Hero__title {
    text-transform: uppercase;
    text-decoration: underline;
    text-decoration-color: black;
    color: transparent; }
    @media screen and (min-width: 48em) {
      .Hero__title--hide {
        display: none; } }
  .Hero__byline {
    position: relative;
    margin-top: 0.625rem;
    font-size: 1.125rem;
    font-weight: 400;
    width: 100%;
    max-width: 43.75rem; }
    @media screen and (min-width: 48em) {
      .Hero__byline {
        margin-top: 1.25rem; } }
    @media screen and (min-width: 64em) {
      .Hero__byline {
        margin-top: 1.25rem; } }
    @media screen and (min-width: 48em) {
      .Hero__byline {
        font-size: 1.25rem; } }
    @media screen and (min-width: 64em) {
      .Hero__byline {
        font-size: 1.25rem; } }
  .Hero__desc {
    margin-top: 3.75rem;
    font-size: 1.25rem;
    width: 100%;
    max-width: 50rem;
    align-self: flex-end;
    font-family: "acumin-pro-wide", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    text-decoration: underline;
    text-decoration-color: black;
    color: transparent; }
    @media screen and (min-width: 48em) {
      .Hero__desc {
        margin-top: 5rem; } }
    @media screen and (min-width: 64em) {
      .Hero__desc {
        margin-top: 5rem; } }
    @media screen and (min-width: 48em) {
      .Hero__desc {
        font-size: 2.125rem; } }
    @media screen and (min-width: 64em) {
      .Hero__desc {
        font-size: 2.125rem; } }

.Split {
  margin-bottom: 3.75rem; }
  @media screen and (min-width: 48em) {
    .Split {
      margin-bottom: 5rem; } }
  @media screen and (min-width: 64em) {
    .Split {
      margin-bottom: 5rem; } }
  @media screen and (min-width: 64em) {
    .Split {
      display: flex;
      align-items: center; } }
  .Split__left {
    margin-bottom: 2.5rem; }
    @media screen and (min-width: 64em) {
      .Split__left {
        margin-bottom: 0;
        margin-right: 0.9375rem;
        flex-basis: 50%;
        width: 50%; }
        .Split__left .Content__image-wrapper {
          padding-right: 5rem; } }
    @media screen and (min-width: 64em) and (min-width: 48em) {
      .Split__left .Content__image-wrapper {
        padding-right: 10rem; } }
    @media screen and (min-width: 64em) and (min-width: 64em) {
      .Split__left .Content__image-wrapper {
        padding-right: 10rem; } }
  @media screen and (min-width: 64em) {
    .Split__right {
      margin-left: 0.9375rem;
      flex-basis: 50%;
      width: 50%; }
      .Split__right .Content__image-wrapper {
        padding-left: 5rem; } }
    @media screen and (min-width: 64em) and (min-width: 48em) {
      .Split__right .Content__image-wrapper {
        padding-left: 10rem; } }
    @media screen and (min-width: 64em) and (min-width: 64em) {
      .Split__right .Content__image-wrapper {
        padding-left: 10rem; } }
  .Split .Content__line {
    display: block;
    max-width: 31.25rem;
    margin: 0 auto; }

.Full {
  margin-bottom: 5rem; }
  @media screen and (min-width: 48em) {
    .Full {
      margin-bottom: 10rem; } }
  @media screen and (min-width: 64em) {
    .Full {
      margin-bottom: 10rem; } }
  .Full__button {
    display: flex;
    justify-content: center; }

.Footer {
  color: white;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  font-size: 1.125rem;
  font-weight: 400;
  background: black; }
  @media screen and (min-width: 48em) {
    .Footer {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem; } }
  @media screen and (min-width: 64em) {
    .Footer {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem; } }
  @media screen and (min-width: 48em) {
    .Footer {
      font-size: 1.25rem; } }
  @media screen and (min-width: 64em) {
    .Footer {
      font-size: 1.25rem; } }
  .Footer__container {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    @media screen and (min-width: 48em) {
      .Footer__container {
        padding-left: 2.5rem;
        padding-right: 2.5rem; } }
  @media screen and (min-width: 48em) and (min-width: 48em) {
    .Footer__container {
      padding-left: 3.75rem;
      padding-right: 3.75rem; } }
  @media screen and (min-width: 48em) and (min-width: 64em) {
    .Footer__container {
      padding-left: 3.75rem;
      padding-right: 3.75rem; } }
  .Footer__contact {
    width: 100%; }
    @media screen and (min-width: 48em) {
      .Footer__contact {
        display: flex;
        width: auto; } }
    @media screen and (min-width: 90em) {
      .Footer__contact {
        width: auto; } }
  @media screen and (min-width: 48em) {
    .Footer__item {
      padding-left: 0.625rem;
      padding-right: 0.625rem; } }
  @media screen and (min-width: 48em) and (min-width: 48em) {
    .Footer__item {
      padding-left: 1.25rem;
      padding-right: 1.25rem; } }
  @media screen and (min-width: 48em) and (min-width: 64em) {
    .Footer__item {
      padding-left: 1.25rem;
      padding-right: 1.25rem; } }
  @media screen and (min-width: 48em) and (min-width: 48em) {
    .Footer__item:first-child {
      padding-left: 0; }
    .Footer__item:last-child {
      padding-right: 0; } }
  .Footer__some, .Footer__privacy {
    width: 100%;
    margin-top: 0.625rem; }
    @media screen and (min-width: 48em) {
      .Footer__some, .Footer__privacy {
        margin-top: 1.25rem; } }
    @media screen and (min-width: 64em) {
      .Footer__some, .Footer__privacy {
        margin-top: 1.25rem; } }
    @media screen and (min-width: 90em) {
      .Footer__some, .Footer__privacy {
        margin-top: 0;
        width: auto; } }
  .Footer a:focus {
    outline-color: white; }

.Creatives {
  display: flex;
  flex-flow: row wrap;
  margin-left: -0.625rem;
  margin-right: -0.625rem; }
  @media screen and (min-width: 48em) {
    .Creatives {
      margin-left: -1.25rem;
      margin-right: -1.25rem; } }
  @media screen and (min-width: 64em) {
    .Creatives {
      margin-left: -1.25rem;
      margin-right: -1.25rem; } }
  .Creatives__title {
    margin-top: 0.625rem;
    text-transform: none; }
    @media screen and (min-width: 48em) {
      .Creatives__title {
        margin-top: 1.25rem; } }
    @media screen and (min-width: 64em) {
      .Creatives__title {
        margin-top: 1.25rem; } }
  .Creatives__item {
    width: 100%;
    margin-bottom: 2.5rem;
    padding-left: 0.625rem;
    padding-right: 0.625rem; }
    @media screen and (min-width: 48em) {
      .Creatives__item {
        margin-bottom: 3.75rem; } }
    @media screen and (min-width: 64em) {
      .Creatives__item {
        margin-bottom: 3.75rem; } }
    @media screen and (min-width: 48em) {
      .Creatives__item {
        padding-left: 1.25rem;
        padding-right: 1.25rem; } }
    @media screen and (min-width: 64em) {
      .Creatives__item {
        padding-left: 1.25rem;
        padding-right: 1.25rem; } }
    @media screen and (min-width: 48em) {
      .Creatives__item {
        width: 50%; } }
    @media screen and (min-width: 64em) {
      .Creatives__item {
        width: 33.333333333%; } }
  .Creatives__image--placeholder {
    background: #dbdbdb; }
  .Creatives__phone, .Creatives__email {
    display: block;
    margin-bottom: 0; }
  .Creatives__bio {
    font-size: 1.125rem;
    font-weight: 400;
    margin-top: 0.625rem; }
    @media screen and (min-width: 48em) {
      .Creatives__bio {
        font-size: 1.25rem; } }
    @media screen and (min-width: 64em) {
      .Creatives__bio {
        font-size: 1.25rem; } }
    @media screen and (min-width: 48em) {
      .Creatives__bio {
        margin-top: 1.25rem; } }
    @media screen and (min-width: 64em) {
      .Creatives__bio {
        margin-top: 1.25rem; } }
  .Creatives__meta {
    font-size: 1.125rem;
    font-weight: 400;
    margin-top: 0.625rem; }
    @media screen and (min-width: 48em) {
      .Creatives__meta {
        font-size: 1.25rem; } }
    @media screen and (min-width: 64em) {
      .Creatives__meta {
        font-size: 1.25rem; } }
    @media screen and (min-width: 48em) {
      .Creatives__meta {
        margin-top: 1.25rem; } }
    @media screen and (min-width: 64em) {
      .Creatives__meta {
        margin-top: 1.25rem; } }
  .Creatives__link {
    font-size: 1.125rem;
    font-weight: 400; }
    @media screen and (min-width: 48em) {
      .Creatives__link {
        font-size: 1.25rem; } }
    @media screen and (min-width: 64em) {
      .Creatives__link {
        font-size: 1.25rem; } }

.Projects {
  min-height: 100vh; }
  .Projects__list {
    display: flex;
    flex-flow: row wrap;
    margin-left: -0.625rem;
    margin-right: -0.625rem; }
    @media screen and (min-width: 48em) {
      .Projects__list {
        margin-left: -1.25rem;
        margin-right: -1.25rem; } }
    @media screen and (min-width: 64em) {
      .Projects__list {
        margin-left: -1.25rem;
        margin-right: -1.25rem; } }
  .Projects__item {
    width: 100%;
    margin-bottom: 2.5rem;
    padding-left: 0.625rem;
    padding-right: 0.625rem; }
    @media screen and (min-width: 48em) {
      .Projects__item {
        margin-bottom: 3.75rem; } }
    @media screen and (min-width: 64em) {
      .Projects__item {
        margin-bottom: 3.75rem; } }
    @media screen and (min-width: 48em) {
      .Projects__item {
        padding-left: 1.25rem;
        padding-right: 1.25rem; } }
    @media screen and (min-width: 64em) {
      .Projects__item {
        padding-left: 1.25rem;
        padding-right: 1.25rem; } }
    @media screen and (min-width: 48em) {
      .Projects__item {
        width: 50%; } }
  .Projects__video, .Projects__image {
    margin-bottom: 0.625rem; }
    @media screen and (min-width: 48em) {
      .Projects__video, .Projects__image {
        margin-bottom: 1.25rem; } }
    @media screen and (min-width: 64em) {
      .Projects__video, .Projects__image {
        margin-bottom: 1.25rem; } }
  .Projects__caption {
    font-size: 1.125rem;
    font-weight: 400; }
    @media screen and (min-width: 48em) {
      .Projects__caption {
        font-size: 1.25rem; } }
    @media screen and (min-width: 64em) {
      .Projects__caption {
        font-size: 1.25rem; } }
  .Projects__no-hits {
    width: 100%;
    min-height: 50vh;
    padding-top: 5rem;
    padding-bottom: 5rem; }
    @media screen and (min-width: 48em) {
      .Projects__no-hits {
        padding-top: 10rem;
        padding-bottom: 10rem; } }
    @media screen and (min-width: 64em) {
      .Projects__no-hits {
        padding-top: 10rem;
        padding-bottom: 10rem; } }
    .Projects__no-hits h2 {
      max-width: 800px;
      margin-left: auto; }
  .Projects__button {
    margin-top: 5rem; }
    @media screen and (min-width: 48em) {
      .Projects__button {
        margin-top: 10rem; } }
    @media screen and (min-width: 64em) {
      .Projects__button {
        margin-top: 10rem; } }

.Filter {
  font-size: 1.125rem;
  font-weight: 400;
  margin-bottom: 2.5rem;
  font-size: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  max-width: 100%;
  flex-wrap: wrap; }
  @media screen and (min-width: 48em) {
    .Filter {
      font-size: 1.25rem; } }
  @media screen and (min-width: 64em) {
    .Filter {
      font-size: 1.25rem; } }
  @media screen and (min-width: 48em) {
    .Filter {
      margin-bottom: 3.75rem; } }
  @media screen and (min-width: 64em) {
    .Filter {
      margin-bottom: 3.75rem; } }
  @media screen and (min-width: 48em) {
    .Filter {
      font-size: 2.125rem; } }
  @media screen and (min-width: 64em) {
    .Filter {
      font-size: 2.125rem; } }
  .Filter__category {
    margin-left: -0.625rem; }
    @media screen and (min-width: 90em) {
      .Filter__category {
        max-width: 60%; } }
  .Filter__person {
    white-space: nowrap;
    font-size: 1.25rem;
    margin-top: 1.875rem;
    margin-left: 0; }
    @media screen and (min-width: 48em) {
      .Filter__person {
        font-size: 2.125rem; } }
    @media screen and (min-width: 64em) {
      .Filter__person {
        font-size: 2.125rem; } }
    @media screen and (min-width: 90em) {
      .Filter__person {
        margin-top: 0; } }
  .Filter__item {
    margin-right: 0.625rem;
    padding: 0.125rem 0.625rem 0.25rem; }
    .Filter__item--is-selected {
      background: white;
      color: black; }

.select {
  position: relative;
  transform: translateY(0.13em); }
  .select__input {
    width: 100%;
    font-size: inherit;
    appearance: none;
    border: none;
    background: none;
    padding-right: 1.875rem; }
  .select__icon {
    pointer-events: none;
    position: absolute;
    top: 0.85em;
    right: 0;
    transform: translateY(-50%);
    margin-left: 0.3125rem; }

.theme {
  padding-top: 1.875rem; }
  @media screen and (min-width: 48em) {
    .theme {
      padding-top: 3.75rem; } }
  @media screen and (min-width: 64em) {
    .theme {
      padding-top: 5rem; } }
  .theme--no-padding {
    padding: 0; }
  .theme--dark {
    background: black;
    color: white; }
    .theme--dark ::selection {
      background: white; }
    .theme--dark .Menu__item {
      border-color: white; }
    .theme--dark select {
      color: white; }
    .theme--dark .button__inner {
      border-color: white; }
      .theme--dark .button__inner__icon path {
        stroke: white; }
      .theme--dark .button__inner:hover {
        background: white;
        color: black; }
        .theme--dark .button__inner:hover .button__icon path {
          stroke: black; }
    .theme--dark .Footer {
      background: white;
      color: black; }
      .theme--dark .Footer .Logo {
        color: black; }
        .theme--dark .Footer .Logo__line {
          fill: black; }
      .theme--dark .Footer a:focus {
        outline-color: black; }
    .theme--dark .SideNav__wrapper {
      background: white;
      color: black; }
      .theme--dark .SideNav__wrapper:after {
        background: white; }
    .theme--dark .Logo {
      color: white; }
      .theme--dark .Logo__line {
        fill: white; }
      .theme--dark .Logo__module img {
        filter: invert(100%); }
    .theme--dark .Hero--show .Hero__title,
    .theme--dark .Hero--show .Hero__desc {
      text-decoration: none;
      color: white; }
    .theme--dark .Hero__title {
      text-decoration: underline;
      text-decoration-color: white;
      color: transparent; }
    .theme--dark .Hero__desc {
      text-decoration: underline;
      text-decoration-color: white;
      color: transparent; }
    .theme--dark .Content--show .Content__text, .theme--dark .Content--show .Content__line {
      text-decoration: none;
      color: white; }
    .theme--dark .Content__text {
      text-decoration: underline;
      text-decoration-color: white;
      color: transparent; }
    .theme--dark .LineLoader {
      background: white; }

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: orange; }

.Project {
  min-height: 100vh; }
  .Project__header {
    display: flex;
    justify-content: space-between;
    align-items: baseline; }
  .Project__feature {
    margin-top: 5rem; }
    @media screen and (min-width: 48em) {
      .Project__feature {
        margin-top: 10rem; } }
    @media screen and (min-width: 64em) {
      .Project__feature {
        margin-top: 10rem; } }
    .Project__feature:last-of-type {
      margin-bottom: 5rem; }
      @media screen and (min-width: 48em) {
        .Project__feature:last-of-type {
          margin-bottom: 10rem; } }
      @media screen and (min-width: 64em) {
        .Project__feature:last-of-type {
          margin-bottom: 10rem; } }
  .Project__caption {
    font-size: 1.125rem;
    font-weight: 400;
    margin-top: 0.625rem;
    text-align: center; }
    @media screen and (min-width: 48em) {
      .Project__caption {
        font-size: 1.25rem; } }
    @media screen and (min-width: 64em) {
      .Project__caption {
        font-size: 1.25rem; } }
    @media screen and (min-width: 48em) {
      .Project__caption {
        margin-top: 1.25rem; } }
    @media screen and (min-width: 64em) {
      .Project__caption {
        margin-top: 1.25rem; } }
  .Project__credits {
    width: 100%;
    font-size: 1.125rem;
    font-weight: 400;
    margin-top: 0.625rem; }
    @media screen and (min-width: 48em) {
      .Project__credits {
        font-size: 1.25rem; } }
    @media screen and (min-width: 64em) {
      .Project__credits {
        font-size: 1.25rem; } }
    @media screen and (min-width: 48em) {
      .Project__credits {
        margin-top: 1.25rem; } }
    @media screen and (min-width: 64em) {
      .Project__credits {
        margin-top: 1.25rem; } }
    @media screen and (min-width: 48em) {
      .Project__credits {
        display: flex;
        justify-content: space-between; } }
    @media screen and (min-width: 48em) {
      .Project__credits-external, .Project__credits-internal {
        width: 50%;
        max-width: 31.25rem; } }
    .Project__credits-external {
      margin-top: 0.625rem; }
      @media screen and (min-width: 48em) {
        .Project__credits-external {
          margin-top: 0; } }
  .Project__no-hits {
    min-height: 35vh;
    display: flex;
    flex-direction: column;
    justify-content: center; }

.Logo {
  font-family: 'acumin-pro-extra-condensed';
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 1;
  display: block;
  display: flex;
  height: 1.875rem;
  align-self: flex-start;
  transform: translateY(-0.15em);
  text-decoration: none; }
  @media screen and (min-width: 48em) {
    .Logo {
      transform: translateY(-0.2em);
      height: 3.125rem;
      max-height: 3.125rem;
      font-size: 3.125rem;
      width: 18.75rem; } }
  .Logo:hover {
    opacity: 1; }
  .Logo:focus {
    outline: none; }
  .Logo--small {
    font-size: 1.25rem; }
    @media screen and (min-width: 48em) {
      .Logo--small {
        font-size: 2.125rem; } }
    @media screen and (min-width: 64em) {
      .Logo--small {
        font-size: 2.125rem; } }
    .Logo--small .Logo__graphic {
      height: 1.25rem; }
      @media screen and (min-width: 48em) {
        .Logo--small .Logo__graphic {
          height: 1.5625rem; } }
      .Logo--small .Logo__graphic--is-final {
        min-width: 1.875rem; }
        @media screen and (min-width: 48em) {
          .Logo--small .Logo__graphic--is-final {
            min-width: 3.125rem; } }
    .Logo--small .Logo__line {
      fill: white;
      top: calc(100% + 0.625rem);
      height: 0.25rem;
      transition: all ease; }
  .Logo__graphic {
    position: relative;
    width: auto;
    height: 1.25rem;
    text-transform: uppercase; }
    @media screen and (min-width: 48em) {
      .Logo__graphic {
        height: 2.5rem; } }
    .Logo__graphic--is-final {
      min-width: 3.75rem; }
      @media screen and (min-width: 48em) {
        .Logo__graphic--is-final {
          min-width: 6.25rem; } }
  .Logo__image {
    width: auto;
    height: 1.25rem;
    max-height: 1.25rem; }
    @media screen and (min-width: 48em) {
      .Logo__image {
        height: 2.5rem;
        max-height: 2.5rem; } }
  .Logo__text {
    margin-right: -0.3125rem; }
  .Logo__line {
    position: absolute;
    top: calc(100% + 0.625rem);
    left: 0;
    width: 100%;
    height: 0.1875rem; }
    @media screen and (min-width: 48em) {
      .Logo__line {
        height: 0.3125rem; } }
  .Logo__name {
    margin-left: 0.3125rem; }

.Loader {
  height: 100vh; }

.lazyload,
.lazyloading {
  text-decoration: underline;
  text-decoration-color: black;
  color: transparent; }

.lazyloaded {
  text-decoration: none;
  color: black; }

.Contacts {
  margin-bottom: 5rem; }
  @media screen and (min-width: 48em) {
    .Contacts {
      margin-bottom: 10rem; } }
  @media screen and (min-width: 64em) {
    .Contacts {
      margin-bottom: 10rem; } }
  .Contacts__item {
    width: 100%;
    border-bottom: 4px solid black;
    margin-bottom: 1.875rem;
    padding-bottom: 1.875rem;
    font-size: 1.125rem; }
    @media screen and (min-width: 48em) {
      .Contacts__item {
        margin-bottom: 2.5rem;
        padding-bottom: 2.5rem; } }
    @media screen and (min-width: 64em) {
      .Contacts__item {
        margin-bottom: 2.5rem;
        padding-bottom: 2.5rem; } }
    @media screen and (min-width: 48em) {
      .Contacts__item {
        font-size: 1.5625rem; } }
    @media screen and (min-width: 64em) {
      .Contacts__item {
        display: flex; } }
    @media screen and (min-width: 90em) {
      .Contacts__item {
        font-size: 1.25rem; } }
  @media screen and (min-width: 90em) and (min-width: 48em) {
    .Contacts__item {
      font-size: 2.125rem; } }
  @media screen and (min-width: 90em) and (min-width: 64em) {
    .Contacts__item {
      font-size: 2.125rem; } }
  .Contacts__info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.625rem; }
    @media screen and (min-width: 48em) {
      .Contacts__info {
        margin-bottom: 2.5rem; } }
    @media screen and (min-width: 64em) {
      .Contacts__info {
        width: 50%;
        margin-bottom: 0; } }
  .Contacts__text {
    flex-grow: 1; }
  .Contacts__name {
    text-transform: initial;
    padding-right: 0.3125rem;
    font-size: 1.125rem; }
    @media screen and (min-width: 48em) {
      .Contacts__name {
        font-size: 1.5625rem; } }
    @media screen and (min-width: 90em) {
      .Contacts__name {
        font-size: 1.25rem; } }
  @media screen and (min-width: 90em) and (min-width: 48em) {
    .Contacts__name {
      font-size: 2.125rem; } }
  @media screen and (min-width: 90em) and (min-width: 64em) {
    .Contacts__name {
      font-size: 2.125rem; } }
  .Contacts__role {
    font-size: 1.125rem; }
    @media screen and (min-width: 48em) {
      .Contacts__role {
        font-size: 1.25rem; } }
    @media screen and (min-width: 64em) {
      .Contacts__role {
        font-size: 1.25rem; } }
  .Contacts__media {
    min-width: 6.5rem; }
  .Contacts__image {
    width: 100%; }
  .Contacts__meta {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    @media screen and (min-width: 48em) {
      .Contacts__meta {
        flex-direction: row; } }
    @media screen and (min-width: 64em) {
      .Contacts__meta {
        width: 50%;
        padding-left: 5rem; } }
  .Contacts__phone {
    white-space: nowrap; }
    @media screen and (min-width: 64em) {
      .Contacts__phone {
        text-align: right; } }

.NoMatch {
  position: relative;
  width: 100%;
  max-width: 100%;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  height: calc( 100vh - 107px - 8.5rem - 50px);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly; }
  @media screen and (min-width: 48em) {
    .NoMatch {
      padding-left: 3.75rem;
      padding-right: 3.75rem; } }
  @media screen and (min-width: 64em) {
    .NoMatch {
      padding-left: 3.75rem;
      padding-right: 3.75rem; } }
  @media screen and (min-width: 48em) {
    .NoMatch {
      max-width: 100rem;
      margin-left: auto;
      margin-right: auto; } }
  @media screen and (min-width: 90em) {
    .NoMatch {
      max-width: 100rem;
      margin-left: auto;
      margin-right: auto; } }
  @media screen and (min-width: 48em) {
    .NoMatch {
      min-height: 500px; } }
  .NoMatch__text {
    font-size: 2.125rem; }
    @media screen and (min-width: 48em) {
      .NoMatch__text {
        font-size: 3.75rem; } }
    @media screen and (min-width: 64em) {
      .NoMatch__text {
        font-size: 3.75rem; } }
    @media screen and (min-width: 48em) {
      .NoMatch__text {
        width: 60%;
        margin-left: auto; } }

.LineLoader {
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  background: black;
  width: 100%;
  transform-origin: top left;
  animation: lineProgress 2s 0.2s linear both; }

@keyframes lineProgress {
  from {
    transform: scaleX(0); }
  to {
    transform: scaleX(1); } }

.VimeoWrapper {
  position: relative; }
  .VimeoWrapper * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .VimeoWrapper:hover .VimeoWrapper__mute {
    opacity: 1;
    animation: scale 0.3s ease both; }
  .VimeoWrapper:hover:after {
    background: transparent; }
  .VimeoWrapper--hidden {
    opacity: 0; }
  .VimeoWrapper__mute {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    animation: fade-out 2s;
    opacity: 0;
    margin-top: 0.625rem;
    margin-left: 0.625rem;
    transition: opacity 0.3s ease; }
    @media screen and (min-width: 48em) {
      .VimeoWrapper__mute {
        margin-top: 1.25rem;
        margin-left: 1.25rem; } }
    @media screen and (min-width: 64em) {
      .VimeoWrapper__mute {
        margin-top: 1.25rem;
        margin-left: 1.25rem; } }
    .VimeoWrapper__mute:focus {
      outline: none; }

@keyframes fade-out {
  0%,
  75% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes scale {
  0% {
    transform: scale(0); }
  50% {
    transform: scale(1.2); }
  100% {
    transform: scale(1); } }

.Carousel {
  position: relative;
  max-width: 100%;
  margin-bottom: 5rem;
  /*! Flickity v2.1.2 https://flickity.metafizzy.co
  ---------------------------------------------- */
  /* draggable */
  /* ---- flickity-button ---- */
  /* ---- previous/next buttons ---- */
  /* right to left */
  /* ---- page dots ---- */
  /* .flickity-slider .gatsby-image-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  } */ }
  @media screen and (min-width: 48em) {
    .Carousel {
      margin-bottom: 10rem; } }
  @media screen and (min-width: 64em) {
    .Carousel {
      margin-bottom: 10rem; } }
  .Carousel .flickity-enabled {
    position: relative; }
  .Carousel .flickity-enabled:focus {
    outline: none; }
  .Carousel .flickity-viewport {
    overflow: hidden;
    position: relative; }
    .Carousel .flickity-viewport .is-selected {
      opacity: 1; }
  .Carousel .flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%; }
  .Carousel .flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    user-select: none; }
  .Carousel .flickity-enabled.is-draggable .flickity-viewport {
    /* cursor: move;
    cursor: -webkit-grab; */
    cursor: grab; }
  .Carousel .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: grabbing; }
  .Carousel .flickity-button {
    /* display: none; */
    position: absolute;
    z-index: 2;
    border: none; }
    .Carousel .flickity-button svg {
      display: none; }
  .Carousel .flickity-button:hover {
    /* background: white; */
    cursor: pointer; }
  .Carousel .flickity-button:focus {
    outline: none;
    box-shadow: 0 0 0 5px #19f; }
  .Carousel .flickity-button:disabled {
    opacity: 0;
    cursor: auto;
    /* prevent disabled button from capturing pointer up event. #716 */
    pointer-events: none; }
  .Carousel .flickity-button-icon {
    fill: black; }
  .Carousel .flickity-prev-next-button {
    top: 50%;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    /* vertically center */
    transform: translateY(-50%); }
    .Carousel .flickity-prev-next-button:after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .Carousel .flickity-prev-next-button.next {
    right: 0;
    left: auto; }
    .Carousel .flickity-prev-next-button.next:after {
      content: '\\2192';
      display: block; }
  .Carousel .flickity-prev-next-button.previous {
    left: 0; }
    .Carousel .flickity-prev-next-button.previous:after {
      content: '\\2190';
      display: block; }
  .Carousel .flickity-rtl .flickity-prev-next-button.previous {
    left: auto;
    right: 10px; }
  .Carousel .flickity-rtl .flickity-prev-next-button.next {
    right: auto;
    left: 10px; }
  .Carousel .flickity-prev-next-button .flickity-button-icon {
    position: absolute;
    left: 30%;
    top: 30%;
    width: 40%;
    height: 40%; }
  .Carousel .item {
    width: 100%;
    opacity: 0.5; }
    .Carousel .item.is-selected {
      opacity: 1; }
  .Carousel .flickity-page-dots {
    display: flex;
    justify-content: center; }
  .Carousel .flickity-page-dots .dot {
    cursor: pointer; }
    .Carousel .flickity-page-dots .dot:before {
      content: '';
      display: inline-block;
      width: 100%;
      height: 2px;
      background: orange; }
  .Carousel .Navigation {
    display: flex;
    justify-content: space-between; }
  .Carousel .Full,
  .Carousel .Split {
    margin-bottom: 0 !important; }

.Layout {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 100vh;
  justify-content: space-between; }

.text-center {
  text-align: center; }

.text-link {
  text-decoration: underline;
  vertical-align: baseline;
  transition: opacity 0.3s ease; }
  .text-link:hover {
    opacity: 0.6; }

.hidden-lg {
  display: none; }
  @media screen and (min-width: 64em) {
    .hidden-lg {
      display: block; } }

.hide-xxl {
  display: none; }
  @media screen and (min-width: 112.5em) {
    .hide-xxl {
      display: block; }
      .hide-xxl--inline {
        display: inline; } }

.show-xxl {
  display: block; }
  .show-xxl--inline {
    display: inline; }
  .show-xxl--inline-flex {
    display: inline-flex; }
  @media screen and (min-width: 112.5em) {
    .show-xxl {
      display: none; } }

.hidden-md {
  display: block; }
  @media screen and (min-width: 64em) {
    .hidden-md {
      display: none; } }

.no-button {
  display: block;
  width: 100%; }

.display-inline {
  display: inline; }

.aspect {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  .aspect:before {
    content: ' ';
    display: block;
    width: 100%;
    height: 0; }
  .aspect-sm--landscape:before {
    padding-top: 56.25%; }
  .aspect-sm--portrait:before {
    padding-top: 150%; }
  .aspect-sm--square:before {
    padding-top: 100%; }
  .aspect-sm--widescreen:before {
    padding-top: 50%; }
  @media screen and (min-width: 48em) {
    .aspect-md--landscape:before {
      padding-top: 56.25%; }
    .aspect-md--portrait:before {
      padding-top: 150%; }
    .aspect-md--square:before {
      padding-top: 100%; }
    .aspect-md--widescreen:before {
      padding-top: 50%; } }
  @media screen and (min-width: 64em) {
    .aspect-lg--landscape:before {
      padding-top: 56.25%; }
    .aspect-lg--portrait:before {
      padding-top: 150%; }
    .aspect-lg--square:before {
      padding-top: 100%; }
    .aspect-lg--widescreen:before {
      padding-top: 50%; } }
  @media screen and (min-width: 90em) {
    .aspect-xl--landscape:before {
      padding-top: 56.25%; }
    .aspect-xl--portrait:before {
      padding-top: 150%; }
    .aspect-xl--square:before {
      padding-top: 100%; }
    .aspect-xl--widescreen:before {
      padding-top: 50%; } }
  @media screen and (min-width: 112.5em) {
    .aspect-xxl--landscape:before {
      padding-top: 56.25%; }
    .aspect-xxl--portrait:before {
      padding-top: 150%; }
    .aspect-xxl--square:before {
      padding-top: 100%; }
    .aspect-xxl--widescreen:before {
      padding-top: 50%; } }

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */ }
